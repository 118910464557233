import { clientApi as api } from '@common/api'
import { slotParams as params } from '@common/constants'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IData, ISlotsResource } from './userSlots.types'


const initialState: ISlotsResource = {
  data: null,
  filters: {},
  isLoading: false,
  errors: null,
}

let requestStatusMap: 'pending' | 'fulfilled' | 'rejected' = 'fulfilled'

export const fetchUserSlots = createAsyncThunk('slots/fetchUserSlots', async () => {
  if(requestStatusMap === 'pending') {
    return null
  }
  requestStatusMap = 'pending'
  try {
    const response = await api.get<IData>('/api/apps/list', {
      params,
    })
    requestStatusMap = 'fulfilled'
    return response
  } catch (error) {
    requestStatusMap = 'rejected'
    throw error
  }
})

const applicationInfoSlice = createSlice({
  name: 'userSlots',
  initialState,
  reducers: {
    setSlots(state, action: PayloadAction<IData>) {
      state.data = action.payload
    },
    setLoadingApplicationInfo(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    unmountApplicationInfo() {
      return initialState
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUserSlots.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUserSlots.fulfilled, (state, action) => {
        state.data = action.payload
        state.isLoading = false
      })
      .addCase(fetchUserSlots.rejected, (state, action) => {
        state.isLoading = false
        state.errors = action.error.message
      })
  },
})

export const { setSlots, setLoadingApplicationInfo, unmountApplicationInfo } = applicationInfoSlice.actions

export default applicationInfoSlice.reducer
