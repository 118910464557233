import { useEffect, useState } from 'react'

export default function useDebounce<D = string>(value: D, delay: number): D {
  const [debouncedValue, setDebouncedValue] = useState<D>(value)
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay]
  )
  return debouncedValue
}
