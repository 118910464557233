export const getUuidFromAppSlug = (appSlug: string): string | null => {
  const shortCode = appSlug.split('-')[0]
  if(shortCode.length !== 8) {
    return null
  }
  return `${shortCode}-0000-0000-0000-000000000000`
}

export const getShortCodeFromUuid = (uuid: string): string | null => {
  const shortCode = uuid.split('-')[0]
  if(shortCode.length !== 8) {
    return null
  }
  return shortCode
}

export const getAppSlug = (appId: string, appName: string): string | null => {
  const shortCode = getShortCodeFromUuid(appId)
  const appSlug = appName.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase()

  if(shortCode === null || shortCode.length !== 8) {
    return null
  }

  return `${shortCode}-${appSlug}`
}
