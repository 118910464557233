import { clientApi as api } from '@common/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IApplicationRating, IAppRatingResource, IFetchParams, IRemoveParams } from './applicationRating.types'


const initialState: IAppRatingResource = {
  data: null,
  filters: {},
  isLoading: false,
  errors: null,
}

const requestStatusMap: { [key: string]: 'pending' | 'fulfilled' | 'rejected' } = {}

export const fetchApplicationRating = createAsyncThunk('applicationRating/fetchApplicationRating', async (params: IFetchParams) => {
  const { id, ...restParams } = params
  if(requestStatusMap[id] === 'pending') {
    return
  }
  requestStatusMap[id] = 'pending'
  try {
    const response = await api.get<IApplicationRating>(`/api/app/${id}/reviews`, { params: { ...restParams, refresh: undefined }, headers: { 'Custom-Timeout': 10000 } })
    requestStatusMap[id] = 'fulfilled'
    return response
  } catch (error) {
    requestStatusMap[id] = 'rejected'
    throw error
  }
})

export const removeReview = createAsyncThunk('applicationRating/removeReview', async (params: IRemoveParams) => {
  const { appId, reviewId } = params
  if(requestStatusMap[`${appId}-${reviewId}`] === 'pending') {
    return
  }
  requestStatusMap[`${appId}-${reviewId}`] = 'pending'
  try {
    const response = await api.delete<IApplicationRating>(`/api/app/${appId}/reviews/${reviewId}`)
    requestStatusMap[`${appId}-${reviewId}`] = 'fulfilled'
    return response
  } catch (error) {
    requestStatusMap[`${appId}-${reviewId}`] = 'rejected'
    throw error
  }
})

export const removeDeveloperResponse = createAsyncThunk('applicationRating/removeDeveloperResponse', async (params: IRemoveParams) => {
  const { appId, reviewId } = params
  if(requestStatusMap[`${appId}-${reviewId}-developer-responce`] === 'pending') {
    return
  }
  requestStatusMap[`${appId}-${reviewId}-developer-responce`] = 'pending'
  try {
    const response = await api.delete<IApplicationRating>(`/api/app/${appId}/reviews/${reviewId}/developer-response`)
    requestStatusMap[`${appId}-${reviewId}-developer-responce`] = 'fulfilled'
    return response
  } catch (error) {
    requestStatusMap[`${appId}-${reviewId}-developer-responce`] = 'rejected'
    throw error
  }
})

const applicationInfoSlice = createSlice({
  name: 'applicationInfo',
  initialState,
  reducers: {
    unmountAppRating() {
      return initialState
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchApplicationRating.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchApplicationRating.fulfilled, (state, action) => {
        if(action.payload) {
          if(state.data && !action.meta.arg.refresh) {
            state.data = {
              ...action.payload,
              items: [...state.data.items, ...action.payload.items],
            }
          } else {
            state.data = action.payload
          }
          state.filters = {
            ...state.filters,
            rating: action.meta.arg.rating,
          }
          state.isLoading = false
        }
      })
      .addCase(fetchApplicationRating.rejected, (state, action) => {
        state.isLoading = false
        state.errors = action.error.message
      })
  },
})

export const { unmountAppRating } = applicationInfoSlice.actions

export default applicationInfoSlice.reducer
