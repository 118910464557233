import { useTranslate } from '@common/hooks'
import type enTranslation from '@common/i18n/translations/en.json'

import Button from '../Button'
import classNames from './styles.module.scss'


interface IProps {
  cancelBtnText: keyof typeof enTranslation,
  isSubmitting?: boolean,
  onClose?: () => void,
  onHide?: () => void,
  submitBtnText: keyof typeof enTranslation,
  submitDisabled?: boolean,
}

ModalFooter.defaultProps = {
  cancelBtnText: 'Cancel',
  onClose: undefined,
  onHide: undefined,
  submitBtnText: 'Apply',
  submitDisabled: false,
  isSubmitting: false,
}

export default function ModalFooter({
  cancelBtnText,
  isSubmitting,
  onClose,
  onHide,
  submitBtnText,
  submitDisabled,
}: IProps): JSX.Element {
  const { translate } = useTranslate()
  return (
    <footer className={classNames.footer}>
      {onHide && (
        <Button
          onClick={onHide}
          data-test="dismiss-btn"
          width="m"
          variant="secondary"
          tabIndex={504}
          className={classNames.button}
        >
          {translate(cancelBtnText)}
        </Button>
      )}
      {onClose && (
        <Button
          onClick={onClose}
          data-test="confirm-btn"
          width="sm"
          tabIndex={501}
          variant="modal"
          isLoading={isSubmitting}
          className={classNames.button}
          disabled={submitDisabled}
        >
          {translate(submitBtnText)}
        </Button>
      )}
    </footer>
  )
}
