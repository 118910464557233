import enTranslation from '@common/i18n/translations/en.json'

interface ITextTranslations {
  [key: string]: typeof enTranslation,
}

const textTranslations: ITextTranslations = {
  en: enTranslation,
}

export default textTranslations
