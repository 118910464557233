import { useAppDispatch, useAppSelector, useIframe, useSession } from '@common/hooks'
import ModalWrapper from '@common/modals/ModalWrapper'
import { getTokenClaims } from '@common/utils/token'
import Footer from '@layouts/Footer'
import { fetchHackathonWinners } from '@redux/slices/applicationList/thunks'
import { setCommon, setTokenClaims } from '@redux/slices/common'
import type { IData as ICommon } from '@redux/slices/common/commonSlice.types'
import { fetchFeatureFlags } from '@redux/slices/featureFlags/featureFlagsSlice'
import { fetchUserSlots } from '@redux/slices/userSlots/userSlots'
import { useRouter } from 'next/router'
import type { ReactNode } from 'react'
import { createContext, useEffect, useState } from 'react'

import Header from './Header'
import ModalGlobalError from './ModalGlobalError'
import classNames from './styles.module.scss'


export const ErrorContext = createContext<{setModalError:(error: { message: string }) => void,}>({
  setModalError: () => {},
})

interface IProps {
  children: ReactNode,
  common: ICommon,
  isFooterVisible: boolean,
  isHeaderVisible: boolean,
}

interface IMessageData {
  func: string;
  token?: string;
}

export default function CommonLayout({ children, common, isFooterVisible, isHeaderVisible }: IProps): JSX.Element {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const initialSaved = useAppSelector(store => store.common.initialSaved)
  const featureFlags = useAppSelector(store => store.featureFlags)
  const [globalError, setGlobalError] = useState<{ message: string }>()
  const [showGlobalError, setShowGlobalError] = useState(false)
  const { requestIframeToken } = useIframe(null)
  const appDirectoryLink = useAppSelector(store => store.common.data.config?.CB_WEB_URL) as string

  useEffect(() => {
    if(!initialSaved) {
      dispatch(setCommon(common))
      if(common.config?.subtenant) {
        sessionStorage.setItem('subtenant', common.config?.subtenant)
      }
    }
  }, [common, initialSaved])

  useEffect(() => {
    if(!featureFlags.data && !featureFlags.isLoading) {
      void dispatch(fetchFeatureFlags())
    }
  }, [])

  useEffect(() => {
    function handleTokenMessage(event: MessageEvent<IMessageData>) {
      if(event.data.func === 'authToken') {
        const data = event.data.token || ''
        const [token, refreshToken] = data.split(',')
        try {
          const tokenClaims = getTokenClaims(token)
          sessionStorage.setItem('token', token)
          sessionStorage.setItem('refreshToken', refreshToken)
          if(tokenClaims) {
            dispatch(setTokenClaims(tokenClaims))
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
    window.addEventListener('message', handleTokenMessage)
    requestIframeToken()
    return () => {
      window.removeEventListener('message', handleTokenMessage)
    }
  }, [appDirectoryLink])

  useEffect(() => {
    if(globalError) {
      setShowGlobalError(true)
    }
  }, [globalError])

  const { isAuthorizedUser } = useSession()
  const userSlotsAreLoaded = useAppSelector(store => Boolean(store.userSlots.data))

  useEffect(() => {
    if(!userSlotsAreLoaded && isAuthorizedUser) {
      void dispatch(fetchUserSlots())
    }
  }, [isAuthorizedUser])

  useEffect(() => {
    if(appDirectoryLink && window && window.self === window.self.top) {
      const path = router.asPath
      const url = `${appDirectoryLink}/v2apps${path}`
      const isDev = process.env.NODE_ENV === 'development'
      if(!isDev) {
        window.location.href = url
      }
    }
  }, [appDirectoryLink])

  const hackathonWinnersSlice = useAppSelector(store => store.hackathonWinners)

  useEffect(() => {
    if(!hackathonWinnersSlice.winners) {
      void dispatch(fetchHackathonWinners())
    }
  }, [hackathonWinnersSlice.winners])

  return (
    <ErrorContext.Provider value={{ setModalError: (error: { message: string }) => setGlobalError(error) }}>
      <main className={classNames.main}>
        {isHeaderVisible && (
          <Header/>
        )}
        {children}
        {isFooterVisible && <Footer />}
      </main>
      <ModalWrapper
        component={ModalGlobalError}
        show={showGlobalError}
        error={globalError}
        onHide={() => {
          setShowGlobalError(false)
        }}
        size="md"
        modalClassName={classNames.modal}
      />
    </ErrorContext.Provider>
  )
}
