import cx from 'classnames'
import Modal from 'react-modal'

import classNames from './styles.module.scss'


interface IProps {
  modalClassName?: string,
  show: boolean,
  onHide: () => void,
  size?: 'sm' | 'md' | 'lg',
  component: any,
  [keyof: string]: any,
}

ModalWrapper.defaultProps = {
  modalClassName: '',
  show: false,
  onHide: undefined,
  component: null,
  size: 'md',
}

export default function ModalWrapper({
  modalClassName,
  show,
  onHide,
  component: ModalComponent,
  size,
  ...props
}: IProps): JSX.Element {
  return (
    <div onClick={(e) => e.stopPropagation()} className={classNames.wrapper} data-test="modal-body">
      <Modal
        isOpen={show}
        closeTimeoutMS={500}
        ariaHideApp={false}
        onRequestClose={onHide}
        className={cx(classNames.modal, modalClassName, size && classNames[size])}
        overlayClassName={classNames.mask}
      >
        <div>
          <main className={classNames.modalBody} data-test="modal-container">
            <button
              onClick={onHide}
              className={classNames.closeButton}
              tabIndex={900}
              data-test="modal-close-btn"
            >
              <i/>
            </button>
            <ModalComponent {...props} onHide={onHide}/>
          </main>
        </div>
      </Modal>
    </div>
  )
}
