import { emptyApplicationsArray } from '@common/constants'
import { useAppSelector } from '@common/hooks'


interface IMyAppResponse {
  isAppAuthor: boolean,
  isMyApp: boolean,
  isMyAppInstalled: boolean,
}

export default function useMyApp(application?: IApplication | null):IMyAppResponse {
  const userSlots = useAppSelector(store => store.userSlots.data?.items || emptyApplicationsArray)

  const isMyApp = userSlots.some(app => app.id === application?.id)

  const isMyAppInstalled = userSlots.some(app => app.id === application?.id && app.isInstalled)

  const isAppAuthor = useAppSelector(store => {
    return Boolean(store.common.data.config?.token?.username === application?.latest?.author)
  })

  return {
    isAppAuthor,
    isMyApp,
    isMyAppInstalled,
  }
}
