import { appListLimits } from '@common/constants'
import type { ActionCreatorWithoutPayload, ActionCreatorWithPayload, PayloadAction, Slice } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import type { AppState } from '../../store'
import type * as t from './applicationList.types'
import { appListFetchThunks, fetchHackathonWinners, installAppFromListSync, oneClickInstallAsync } from './thunks'


export const initialState: t.IApplicationList = {
  data: null,
  filters: {
    offset: 0,
    limit: appListLimits,
    search: undefined,
  },
  isLoading: false,
  errors: null,
  initialSaved: false,
  winners: null,
}

function generateListSlice(name: AppListReducer): Slice<t.IApplicationList> {
  const slice = createSlice({
    name,
    initialState,
    reducers: {
      [`${name}Initialize`]: (state, action: PayloadAction<t.IData>) => {
        state.data = action.payload
        if(name === 'hackathonWinners' && (action.payload?.offset === 0 || !action.payload?.offset)) {
          state.winners = action.payload.items.slice(0, 3)
        }
        state.filters.search = action.payload?.search
        state.filters.offset = action.payload?.offset
        state.filters.limit = action.payload?.limit
        state.initialSaved = true
      },
      [`${name}Unmount`]: (state) => {
        Object.assign(state, initialState)
      },
      updateAppAfterInstallation(state, action: PayloadAction<IApplication>) {
        if(state.data?.items) {
          state.data.items = state.data.items.map(app => {
            if(app.id === action.payload.id) {
              return action.payload
            }
            return app
          })
        }
      },
    },
    extraReducers: builder => {
      builder
        // ssr requests
        .addCase(HYDRATE, (state, action) => {
          const typedAction = action as unknown as { payload: AppState }
          const winners = typedAction.payload[name].winners || state.winners
          return {
            ...state,
            ...typedAction.payload[name],
            winners,
          }
        })
        .addCase(appListFetchThunks[name].pending, (state) => {
          state.isLoading = true
        })
        .addCase(appListFetchThunks[name].fulfilled, (state, action) => {
          if(action.payload) {
            state.data = action.payload
            state.filters.offset = action.payload.offset
            state.filters.limit = action.payload.limit
          }
          if(action.meta.arg.search) {
            state.filters.search = action.meta.arg.search
          } else {
            state.filters.search = undefined
          }
          state.errors = null
          state.isLoading = false
          state.initialSaved = true
        })
        .addCase(appListFetchThunks[name].rejected, (state, action) => {
          state.isLoading = false
          state.initialSaved = true
          state.errors = action.payload
        })
        .addCase(oneClickInstallAsync.pending, (state, action) => {
          const { appId } = action.meta.arg
          if(state.data?.items) {
            state.data.items = state.data.items.map(app => {
              if(app.id === appId) {
                return {
                  ...app,
                  appLoading: true,
                }
              }
              return app
            })
          }
        })
        .addCase(oneClickInstallAsync.fulfilled, (state, action) => {
          const { appId, slot } = action.meta.arg
          if(state.data?.items) {
            state.data.items = state.data.items.map(app => {
              if(app.id === appId) {
                return {
                  ...app,
                  appLoading: false,
                  isActive: true,
                  isInstalled: true,
                  slot,
                }
              }
              return app
            })
          }
        })
        .addCase(oneClickInstallAsync.rejected, (state, action) => {
          const { appId } = action.meta.arg
          if(state.data?.items) {
            state.data.items = state.data.items.map(app => {
              if(app.id === appId) {
                return {
                  ...app,
                  appLoading: false,
                }
              }
              return app
            })
          }
        })
        .addCase(installAppFromListSync, (state, action) => {
          const { id, slot } = action.payload
          if(state.data?.items) {
            state.data.items = state.data.items.map(app => {
              if(app.id === id) {
                return {
                  ...app,
                  appLoading: false,
                  isActive: true,
                  isInstalled: true,
                  slot,
                }
              }
              return app
            })
          }
        })
        .addCase(fetchHackathonWinners.fulfilled, (state, action) => {
          if(action.payload) {
            state.winners = action.payload.items
          }
          state.isLoading = false
        })
        .addCase(fetchHackathonWinners.pending, (state) => {
          state.isLoading = true
        })
        .addCase(fetchHackathonWinners.rejected, (state, action) => {
          state.isLoading = false
          state.errors = action.payload
          state.winners = []
        })
    },
  })
  return slice
}

const appListReducers = {
  topEarning: generateListSlice('topEarning'),
  trendingApps: generateListSlice('trendingApps'),
  newAndPopular: generateListSlice('newAndPopular'),
  searchApps: generateListSlice('searchApps'),
  recentlyUpdated: generateListSlice('recentlyUpdated'),
  recentlyUsed: generateListSlice('recentlyUsed'),
  hackathonWinners: generateListSlice('hackathonWinners'),
}

export const topEarningInitialize = appListReducers.topEarning.actions.topEarningInitialize as ActionCreatorWithPayload<t.IData, 'topEarning/topEarningInitialize'>
export const trendingAppsInitialize = appListReducers.trendingApps.actions.trendingAppsInitialize as ActionCreatorWithPayload<t.IData, 'trendingApps/trendingAppsInitialize'>
export const newAndPopularInitialize = appListReducers.newAndPopular.actions.newAndPopularInitialize as ActionCreatorWithPayload<t.IData, 'newAndPopular/newAndPopularInitialize'>
export const recentlyUpdatedInitialize = appListReducers.recentlyUpdated.actions.recentlyUpdatedInitialize as ActionCreatorWithPayload<t.IData, 'recentlyUpdated/recentlyUpdatedInitialize'>
export const recentlyUsedInitialize = appListReducers.recentlyUsed.actions.recentlyUsedInitialize as ActionCreatorWithPayload<t.IData, 'recentlyUsed/recentlyUsedInitialize'>
export const recentlyUsedUnmount = appListReducers.recentlyUsed.actions.recentlyUsedUnmount as ActionCreatorWithoutPayload<'recentlyUsed/recentlyUsedUnmount'>
export const searchAppsInitialize = appListReducers.searchApps.actions.searchAppsInitialize as ActionCreatorWithPayload<t.IData, 'searchApps/searchAppsInitialize'>
export const hackathonWinnersInitialize = appListReducers.hackathonWinners.actions.hackathonWinnersInitialize as ActionCreatorWithPayload<t.IData, 'hackathonWinners/hackathonWinnersInitialize'>

export const actions = {
  topEarning: {
    updateAppAfterInstallation: appListReducers.topEarning.actions.updateAppAfterInstallation,
  },
  trendingApps: {
    updateAppAfterInstallation: appListReducers.trendingApps.actions.updateAppAfterInstallation,
  },
  newAndPopular: {
    updateAppAfterInstallation: appListReducers.newAndPopular.actions.updateAppAfterInstallation,
  },
  searchApps: {
    updateAppAfterInstallation: appListReducers.searchApps.actions.updateAppAfterInstallation,
  },
  recentlyUpdated: {
    updateAppAfterInstallation: appListReducers.recentlyUpdated.actions.updateAppAfterInstallation,
  },
  recentlyUsed: {
    updateAppAfterInstallation: appListReducers.recentlyUsed.actions.updateAppAfterInstallation,
  },
  hackathonWinners: {
    updateAppAfterInstallation: appListReducers.hackathonWinners.actions.updateAppAfterInstallation,
  },
}

const slices = {
  topEarning: appListReducers.topEarning.reducer,
  trendingApps: appListReducers.trendingApps.reducer,
  newAndPopular: appListReducers.newAndPopular.reducer,
  searchApps: appListReducers.searchApps.reducer,
  recentlyUpdated: appListReducers.recentlyUpdated.reducer,
  recentlyUsed: appListReducers.recentlyUsed.reducer,
  hackathonWinners: appListReducers.hackathonWinners.reducer,
}


export default slices
