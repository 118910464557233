import { useEffect } from 'react'

interface IUseIframe {
  requestIframeToken: () => void
  showLoginOverlay: () => void
}

interface IUseIframeParams {
  title?: string
  url?: string
  params?: { [key: string]: string | string[] | null }
  onDashboardHasUpdate?: () => void;
}

export default function useIframe(iframeOptions: IUseIframeParams | null):IUseIframe {
  useEffect(() => {
    const parent = typeof window !== 'undefined' && window.parent
    let handleMessage: (event: MessageEvent) => void = () => {}
    if(parent && iframeOptions) {
      iframeOptions.title && window.parent.postMessage({
        func: 'setTitle',
        message: iframeOptions.title,
      }, '*')
      iframeOptions.url && window.parent.postMessage({
        func: 'changeUrlPath',
        message: iframeOptions.url,
      }, '*')
      iframeOptions.params && window.parent.postMessage({
        func: 'changeParams',
        message: iframeOptions.params,
      }, '*')
      handleMessage = (event: MessageEvent) => {
        if(event.data === 'asp-tab-refresh' && iframeOptions?.onDashboardHasUpdate) {
          iframeOptions.onDashboardHasUpdate()
        }
      }
      window.addEventListener('message', handleMessage)
    }
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [iframeOptions])

  const showLoginOverlay = () => {
    window.parent.postMessage({
      func: 'showLoginOverlay',
    }, '*')
  }

  const requestIframeToken = () => {
    window.parent.postMessage({
      func: 'getToken',
    }, '*')
  }

  return { requestIframeToken, showLoginOverlay }
}
