import cx from 'classnames'
import type { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'

import classNames from './styles.module.scss'


interface IProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  dataTest: string,
}

function CloseButton({
  dataTest,
  className,
  ...props
}: IProps): JSX.Element {
  return (
    <button className={cx(classNames.closeButton, className)} data-test={dataTest} {...props}>
    <i className={classNames.closeIcon}/>
  </button>
  )
}

CloseButton.defaultProps = {
  dataTest: undefined,
}

export default CloseButton
