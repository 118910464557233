import { useAppSelector } from '@common/hooks'

interface IUseWhitelabel {
  isWhitelabel: boolean,
  siteName: string,
}

export default function useWhitelabel(): IUseWhitelabel {
  const token = useAppSelector(store => store.common?.data?.config?.token) as ITokenClaims
  const isWhitelabel = token?.site_name === 'Chaturbate'
  const siteName = token?.site_name

  return {
    isWhitelabel,
    siteName,
  }
}
