import { clientApi as api } from '@common/api'
import { getAppSlug } from '@common/utils/uuid'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import { HYDRATE } from 'next-redux-wrapper'

import type { AppState } from '../../store'
import type { IApplicationResource } from './application.types'

const initialState: IApplicationResource = {
  data: null,
  filters: {},
  isLoading: false,
  errors: null,
  autoUpdate: true,
}

const requestStatusMap: { [key: string]: 'pending' | 'fulfilled' | 'rejected' } = {}

export const fetchApplicationInfo = createAsyncThunk('applicationInfo/fetchApplicationInfo', async (application_id: string, { rejectWithValue }) => {
  if(requestStatusMap[application_id] === 'pending') {
    return null
  }
  requestStatusMap[application_id] = 'pending'
  try {
    const response = await api.get<IApplication>(`/api/app/${application_id}`, { headers: { 'Custom-Timeout': 10000 } })
    requestStatusMap[application_id] = 'fulfilled'
    return response
  } catch (error) {
    const axiosError = error as AxiosError
    if(axiosError.response && axiosError.response.data) {
      return rejectWithValue(axiosError.response.data)
    }
    requestStatusMap[application_id] = 'rejected'
    throw error
  }
})

const applicationInfoSlice = createSlice({
  name: 'applicationInfo',
  initialState,
  reducers: {
    setApplication(state, action: PayloadAction<IApplication>) {
      state.data = {
        ...action.payload,
        slug: getAppSlug(action.payload.id, action.payload.name),
      }
    },
    setAutoupdate(state, action: PayloadAction<boolean>) {
      state.autoUpdate = action.payload
    },
    setLoadingApplicationInfo(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    stopApplication(state) {
      if(state.data) {
        state.data.isActive = false
      }
    },
    uninstallApplication(state) {
      if(state.data) {
        state.data.isActive = false
        state.data.isInstalled = false
      }
    },
    unmountApplicationInfo() {
      return initialState
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchApplicationInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchApplicationInfo.fulfilled, (state, action) => {
        if(action.payload) {
          state.data = action.payload
        }
        state.isLoading = false
      })
      .addCase(fetchApplicationInfo.rejected, (state, action) => {
        state.isLoading = false
        state.errors = action.payload
      })
      .addCase(HYDRATE, (state, action) => {
        const typedAction = action as unknown as { payload: AppState }
        const { application } = typedAction.payload
        if(application.data) {
          return {
            ...state,
            ...application,
          }
        }
        return {
          ...state,
        }
      })
  },
})

export const { setApplication, setAutoupdate, setLoadingApplicationInfo, unmountApplicationInfo, stopApplication, uninstallApplication } = applicationInfoSlice.actions


export default applicationInfoSlice.reducer
