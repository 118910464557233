import { AppDirectoryApiError } from '@common/api/AppDirectoryApiError'
import jwt from 'jsonwebtoken'
import jwt_decode from 'jwt-decode'


export function getTokenClaimsWithSecretValidation(token?: string): ITokenClaims | undefined {
  if(!token) { return undefined }
  try {
    const secret = process.env.ASP_SHARED_SECRET
    if(!secret) {
      throw new AppDirectoryApiError(500, 'Server Error', {
        msg: 'Secret key not provided',
      })
    }
    const decoded = jwt.verify<ITokenClaims>(token, secret)
    return {
      ...decoded,
      encoded: token,
    }
  } catch (error) {
    const e = error as Error
    throw new AppDirectoryApiError(401, 'Unauthorized', {
      msg: `JWT: Invalid token - ${e.toString()}`,
    })
  }
}

export function getTokenClaims(token?: string): ITokenClaims | undefined {
  if(!token) { return undefined }
  try {
    const decoded = jwt_decode(token)
    return Object.assign(decoded as ITokenClaims, { encoded: token })
  } catch (e) {
    throw new AppDirectoryApiError(401, 'Unauthorized', {
      msg: 'JWT: Invalid token',
    })
  }
}
