import '@styles/globals.scss'
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css'
import 'swiper/css/pagination'
import '../styles/progress.scss'

import getSubtenantFromQueryParams from '@common/utils/subtenant'
import ScrollToTop from '@common/widgets/ScrollToTop'
import CommonLayout from '@layouts/CommonLayout'
import { wrapper } from '@redux/store'
import type { IncomingMessage, ServerResponse } from 'http'
import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import getConfig from 'next/config'
import { Router, useRouter } from 'next/router'
import NProgress from 'nprogress'
import { Provider } from 'react-redux'
import type { Store } from 'redux'


NProgress.configure({
  showSpinner: false,
  minimum: 0.3,
})
Router.events.on('routeChangeStart', (_: string, config: { shallow?: boolean }) => {
  if(!config?.shallow) {
    NProgress.start()
  }
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

// suppress error until next/link is fixed
// error is thrown when spamming a next/link
// https://github.com/vercel/next.js/issues/46329
const originalConsoleError = console.error
console.error = (e: { message: string } | undefined) => {
  const msg = e?.message || ''
  if(msg.includes('Abort fetching component')) {
    return
  }
  originalConsoleError(e)
}

interface IProps {
  Component: any,
  pageProps: any,
  config: IConfig,
  referrer?: string,
}

AppPage.defaultProps = {
  referrer: undefined,
}

export function AppPage({ Component, config, ...rest }: IProps): JSX.Element {
  const { store, props } = wrapper.useWrappedStore(rest) as { store: Store, props: AppProps }
  const router = useRouter()
  const { slot_id } = router.query
  const isHeaderVisible = !['/apps/[appId]', '/404', '/-/healthy'].some(path => router.pathname.includes(path))
  const isFooterVisible = !router.pathname.includes('/apps/[appId]/[type]')
  return (
    <Provider store={store}>
      <CommonLayout
        isFooterVisible={isFooterVisible}
        isHeaderVisible={isHeaderVisible}
        common={{
          config,
          slotId: (typeof slot_id !== 'object' && !Number.isNaN(Number(slot_id))) ? Number(slot_id) : undefined,
        }}
      >
        <ScrollToTop />
        <Component {...props.pageProps}/>
      </CommonLayout>
    </Provider>
  )
}

AppPage.getInitialProps = async (appContext: AppContext) => {
  const initialProps = await App.getInitialProps(appContext)
  const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: IConfig }
  const { req } = appContext.ctx as { req: IncomingMessage, res: ServerResponse }
  const subtenant = getSubtenantFromQueryParams(req)
  const props = {
    ...initialProps,
    config: { ...publicRuntimeConfig, subtenant },
    referrer: req?.headers?.referer,
  }

  return props
}

export default AppPage
