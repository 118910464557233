import { clientApi as api } from '@common/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IFeatureFlagsResource, IFeatureFlagsResponse } from './featureFlagsSlice.types'

const initialState: IFeatureFlagsResource = {
  data: null,
  errors: null,
  isLoading: false,
  filters: {},
}

let featureFlagsAreSet = false

export const fetchFeatureFlags = createAsyncThunk('slots/fetchFeatureFlags', async () => {
  if(!featureFlagsAreSet) {
    featureFlagsAreSet = true
    return api.get<IFeatureFlagsResponse>('/api/profile')
  }
})

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchFeatureFlags.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFeatureFlags.fulfilled, (state, action) => {
        if(action.payload) {
          state.data = action.payload.data.waffle
          state.isLoading = false
        }
      })
      .addCase(fetchFeatureFlags.rejected, (state, action) => {
        state.isLoading = false
        state.errors = action.error.message
      })
  },
})

export default featureFlagsSlice.reducer
