import Spinner from '@common/widgets/Spinner'
import cx from 'classnames'
import Link from 'next/link'
import type { ButtonHTMLAttributes, HTMLProps, PropsWithoutRef, ReactNode } from 'react'

import styles from './styles.module.scss'


interface IProps extends HTMLProps<HTMLAnchorElement> {
  children: ReactNode
  href?: string
  variant?: 'dark' | 'light' | 'modal' | 'secondary' | 'transparent' | 'transparent-blue' | 'icon'
  className?: string,
  width?: 'lg' | 'md' | 'm' | 'sm' | 'xs' | 'auto',
  isLoading?: boolean,
}

function Button({
  children,
  variant,
  href,
  width,
  disabled,
  isLoading,
  ...rest
}: PropsWithoutRef<ButtonHTMLAttributes<HTMLButtonElement> & IProps>): JSX.Element {
  const className = cx(styles.button, variant && styles[variant], width && styles[width], isLoading && styles.isLoading, rest.className)
  if(href && !disabled) {
    return <Link href={href} legacyBehavior>
      <a {...rest} className={className}>{children}</a>
    </Link>
  }
  return (
    <button {...rest} className={className} disabled={disabled}>
      {isLoading && <Spinner className={styles.spinner}/>}
      {!isLoading && <span className={styles.content}>{children}</span>}
    </button>
  )
}

Button.defaultProps = {
  variant: 'dark',
  width: 'md',
  isLoading: false,
}

export default Button
