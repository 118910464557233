import commonSlice, { setCarouselsInfo, setCommon, setPreviousListPage, setResetCarousels, setTokenClaims } from './commonSlice'

export {
  setCarouselsInfo,
  setCommon,
  setPreviousListPage,
  setResetCarousels,
  setTokenClaims,
}

export default commonSlice
