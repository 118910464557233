import { clientApi as api } from '@common/api'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IVersion, IVersionsResource } from './appVersions.types'


const initialState: IVersionsResource = {
  data: null,
  filters: {},
  isLoading: false,
  errors: null,
}

let requestStatusMap: 'pending' | 'fulfilled' | 'rejected' = 'fulfilled'

export const fetchAppVersion = createAsyncThunk('slots/fetchAppVersions', async (appId: string) => {
  if(requestStatusMap === 'pending') {
    return null
  }
  requestStatusMap = 'pending'
  try {
    const response = await api.get<IVersion[]>(`/api/app/${appId}/versions`, {
    })
    requestStatusMap = 'fulfilled'
    return response
  } catch (error) {
    requestStatusMap = 'rejected'
    throw error
  }
})

const applicationInfoSlice = createSlice({
  name: 'userSlots',
  initialState,
  reducers: {
    setVersions(state, action: PayloadAction<IVersion[]>) {
      state.data = action.payload
    },
    setLoadingVersions(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    unmountVersionsInfo() {
      return initialState
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAppVersion.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAppVersion.fulfilled, (state, action) => {
        state.data = action.payload
        state.isLoading = false
      })
      .addCase(fetchAppVersion.rejected, (state, action) => {
        state.isLoading = false
        state.errors = action.error.message
      })
  },
})

export const { setVersions, setLoadingVersions, unmountVersionsInfo } = applicationInfoSlice.actions

export default applicationInfoSlice.reducer
