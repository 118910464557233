export const appListLimits = 50
export const ASP_APP_DIRECTORY_LOCAL_URL = 'http://localhost:3000'

export const sortOrders = {
  topEarning: { 'order-by': '-topEarnings' },
  trendingApps: { 'order-by': '-trending' },
  newAndPopular: { 'order-by': '-popular', 'created-since': 30 },
  recentlyUpdated: { 'order-by': '-updatedAt' },
  recentlyUsed: { 'order-by': '-recentlyUsed', 'is-recently-used': true },
  hackathonWinners: { 'order-by': '-featured' },
  searchApps: { },
}

export const compositeQueryObjectValue = {
  paramsList: [
    { key: 'topEarning', orderBy: ['-topEarnings'], limit: 59 },
    { key: 'trendingApps', orderBy: ['-trending'], limit: 25 },
    { key: 'newAndPopular', orderBy: ['-popular'], limit: 25, createdSince: 30 },
    { key: 'recentlyUpdated', orderBy: ['-updatedAt'], limit: 25 },
    { key: 'hackathonWinners', orderBy: ['-featured'], limit: 25 },
  ],
}

export const emptyApplicationsArray: IApplication[] = []

export const slotParams = {
  'is-installed': 'true',
  limit: 10,
  'order-by': 'installedAt',
}

export const tabIndexesByReducer: {[reducer in AppListReducer]: number} = {
  hackathonWinners: 100,
  topEarning: 200,
  trendingApps: 300,
  newAndPopular: 400,
  searchApps: 200,
  recentlyUpdated: 500,
  recentlyUsed: 600,
}

export const ttl: number = process.env.MODEL_CACHE_TTL ? 1000 * Number(process.env.MODEL_CACHE_TTL || 0) : 0

export const listLinks = {
  topEarning: 'top-earning',
  newAndPopular: 'new-and-popular',
  trendingApps: 'trending',
  searchApps: 'search',
  recentlyUpdated: 'recently-updated',
  recentlyUsed: 'recently-used',
  hackathonWinners: 'hackathon-winners',
}
