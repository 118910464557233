import { useTranslate } from '@common/hooks'
import cx from 'classnames'
import { DateTime } from 'luxon'
import type { ReactNode } from 'react'

import classNames from './styles.module.scss'


interface IProps {
  children?: ReactNode,
  sticky?: boolean
  className?: string,
}

Footer.defaultProps = {
  children: null,
  sticky: false,
  className: undefined,
}

export default function Footer({ children, sticky, className }: IProps): JSX.Element {
  const d = DateTime.now().get('year')
  const { translate, interpolate } = useTranslate()

  return (
    <footer className={cx(classNames.wrapper, sticky && classNames.sticky, className)} data-test="copyright-text">
      <div className={classNames.copyrightText}>{interpolate(translate('© Copyright Chaturbate.com 2011 - %s. All Rights Reserved.'), [d])}</div>
      {children}
    </footer>
  )
}
