import { useDebounce, usePrevious, useTranslate } from '@common/hooks'
import cx from 'classnames'
import Image from 'next/image'
import { useRouter } from 'next/router'
import type { InputHTMLAttributes } from 'react'
import { useEffect, useRef, useState } from 'react'

import CloseButton from '../CloseButton'
import classNames from './styles.module.scss'


const debounceDelay = 300

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string,
}

function Search({ className, ...props }: IProps): JSX.Element {
  const { query, push, pathname } = useRouter()
  const [search, setSearch] = useState(query.q)
  const debouncedInputValue = useDebounce(search, debounceDelay) as string
  const { translate } = useTranslate()
  const inputRef = useRef<HTMLInputElement | null>(null)

  const oldSearchQuery = usePrevious(query.q)
  const oldDebouncedInputValue = usePrevious(debouncedInputValue)

  const searchQueryWasChanged = oldSearchQuery !== query.q
  const searchInputWasChanged = oldDebouncedInputValue !== debouncedInputValue
  const isQueryEqualInput = debouncedInputValue === (query.q || '')

  useEffect(() => {
    if(pathname === '/apps') {
      setSearch('')
    }
  }, [pathname])

  useEffect(() => {
    const handler = setTimeout(() => {
      if(searchQueryWasChanged && query.q === undefined) {
        setSearch('')
      }
      if(!searchInputWasChanged && searchQueryWasChanged) {
        setSearch(query.q || '')
      }
    }, debounceDelay + 5)
    return () => {
      clearTimeout(handler)
    }
  }, [query.q, searchQueryWasChanged, searchInputWasChanged])

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useEffect(() => {
    if(search === undefined || !searchInputWasChanged || isQueryEqualInput) {
      return
    }
    const routeOptions = {
      pathname,
      query: search ? { q: search } : undefined,
    }

    if(pathname === '/apps' && Boolean(search)) {
      routeOptions.pathname = '/apps/search'
      void push(routeOptions)
    } else if(pathname === '/apps/search' && !search) {
      routeOptions.pathname = '/apps'
      void push(routeOptions)
    } else {
      void push(routeOptions, undefined, { shallow: true })
    }
  }, [debouncedInputValue, isQueryEqualInput, searchInputWasChanged, search, pathname, push])

  const handleClearSearch = () => setSearch('')

  return (
    <div className={cx(classNames.wrapper, className)}>
      <div className={classNames.inputWrapper}>
        <Image src="/icons/search.svg" alt="Search icon" height={40} width={40} />
        {search && (
          <CloseButton
            dataTest="app-search-btn"
            tabIndex={14}
            onClick={handleClearSearch}
          />
        )}
        <input
          data-test="app-search-input"
          className={classNames.searchInput}
          type="text"
          ref={inputRef}
          value={search || ''}
          placeholder={translate('Search author or app name')}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
          tabIndex={10}
          {...props}
        />
      </div>
    </div>
  )
}

Search.defaultProps = {
  className: undefined,
}


export default Search
