import cx from 'classnames'

import classNames from './styles.module.scss'


interface IProps {
  type?: string,
  className?: string,
}

Spinner.defaultProps = {
  className: undefined,
  type: '',
}

export default function Spinner({
  type,
  className,
}: IProps): JSX.Element {
  return (
    <span className={cx(
      classNames.spinnerWrapper,
      type && classNames[type],
      className
    )}>
      <span className={classNames.spinnerItem}/>
      <span className={classNames.spinnerItem}/>
      <span className={classNames.spinnerItem}/>
      <span className={classNames.spinnerItem}/>
    </span>
  )
}
