import { useAppDispatch, useAppSelector, useTranslate } from '@common/hooks'
import useWhitelabel from '@common/hooks/useWhitelabel'
import Button from '@common/widgets/Button'
import Search from '@common/widgets/Search/Search'
import { setResetCarousels } from '@redux/slices/common'
import cx from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { ReactNode } from 'react'

import classNames from './styles.module.scss'


interface IProps {
  children?: ReactNode,
  className?: string,
}

Header.defaultProps = {
  children: null,
  className: undefined,
}

export default function Header({ children, className }: IProps): JSX.Element {
  const { isWhitelabel } = useWhitelabel()
  const { translate } = useTranslate()
  const dispatch = useAppDispatch()
  const devPortalLink = useAppSelector(store => store.common.data.config?.ASP_DEV_PORTAL_URL)

  const { pathname } = useRouter()

  const handleResetCarousel = () => {
    dispatch(setResetCarousels(true))
  }

  return (
    <>
      <header className={cx(classNames.header, className)}>
        <>
          <div className={classNames.logo}>
            { isWhitelabel && (
              <Link href="/apps" legacyBehavior>
                <a
                  data-test="root-btn"
                  tabIndex={4}
                  className={classNames.logoLink}
                  onClick={pathname === '/apps' ? handleResetCarousel : undefined}
                >
                  <Image src="/icons/logo.svg" alt="App Directory logo" width={196} height={62}/>
                </a>
              </Link>
            ) }
          </div>
          { Boolean(children) && children }
          { !children && (
            <>
              <Search className={classNames.search}/>
              <Button
                variant="light"
                href={devPortalLink}
                target="_blank"
                className={classNames.dpBtn}
                tabIndex={7}
                data-test="dev-portal-btn"
                >
                  {translate('Developer Portal')}
                </Button>
            </>
          ) }
        </>
      </header>
  </>
  )
}
