import useWhitelabel from '@common/hooks/useWhitelabel'

import { useAppDispatch, useAppSelector } from './redux'
import useDebounce from './useDebounce'
import useErrorMessages from './useErrorMessages'
import useIframe from './useIframe'
import useMyApp from './useMyApp'
import useOutsideClick from './useOutsideClick'
import usePermissionText from './usePermissionText'
import usePrevious from './usePrevious'
import useSession from './useSession'
import useSlots from './useSlots'
import useTranslate from './useTranslate'


export {
  useAppDispatch,
  useAppSelector,
  useDebounce,
  useErrorMessages,
  useIframe,
  useMyApp,
  useOutsideClick,
  usePermissionText,
  usePrevious,
  useSession,
  useSlots,
  useTranslate,
  useWhitelabel,
}
