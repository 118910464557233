import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { HYDRATE } from 'next-redux-wrapper'

import type { ICarouselData, ICommon, IData } from './commonSlice.types'

const initialState: ICommon = {
  data: {
    config: undefined,
    slotId: undefined,
    carouselsDefaultSlides: {},
    isResetCarousels: false,
    previousListPage: null,
  },
  initialSaved: false,
  errors: null,
}

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    resetSlotId(state) {
      if(state?.data?.slotId) {
        state.data.slotId = undefined
      }
    },
    setCommon(state, action: PayloadAction<IData>) {
      if(!state.initialSaved) {
        state.data = {
          ...state.data,
          ...action.payload,
        }
        state.initialSaved = true
      }
    },
    setTokenClaims(state, action: PayloadAction<ITokenClaims>) {
      if(state.data.config) {
        state.data.config.token = action.payload
        return
      }
      state.data.config = {
        token: action.payload,
      } as IData['config']
    },
    setCarouselsInfo(state, action: PayloadAction<ICarouselData>) {
      state.data.carouselsDefaultSlides = {
        ...state.data.carouselsDefaultSlides,
        ...action.payload,
      }
    },
    setResetCarousels(state, action: PayloadAction<boolean>) {
      state.data.isResetCarousels = action.payload
    },
    setPreviousListPage(state, action: PayloadAction<string | null>) {
      state.data.previousListPage = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(HYDRATE, (state, action) => {
        const common = get(action, 'payload.common.data', {})
        return {
          ...state,
          ...common,
        }
      })
  },
})

export const { resetSlotId, setCommon, setCarouselsInfo, setResetCarousels, setPreviousListPage, setTokenClaims } = commonSlice.actions

export default commonSlice.reducer
