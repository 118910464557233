import { useRouter } from 'next/router'
import { useEffect } from 'react'


const ScrollToTop = (): null => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      const nextWrapper = document.querySelector('#__next')
      if(nextWrapper) {
        nextWrapper.scrollTo(0, 0)
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  return null
}

export default ScrollToTop
