import { ASP_APP_DIRECTORY_LOCAL_URL } from '@common/constants'
import axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'
import axiosRetry from 'axios-retry'
import http from 'http'
import https from 'https'

const httpAgent = new http.Agent({
  keepAlive: true,
  keepAliveMsecs: 60000,
})

const httpsAgent = new https.Agent({
  maxVersion: 'TLSv1.2',
  minVersion: 'TLSv1.2',
  keepAlive: true,
  keepAliveMsecs: 60000,
})

const clientApi = axios.create()
const serverApi = axios.create({ httpAgent, httpsAgent })
const nextApiInstance = axios.create({ httpAgent, httpsAgent })

axiosRetry(nextApiInstance, { retries: 3 })
axiosRetry(serverApi, { retries: 3 })

nextApiInstance.interceptors.request.use((config) => {
  if(config.method === 'get') {
    const customTimeout = Number(config.headers['custom-timeout'])
    config.timeout = Number.isNaN(customTimeout) ? 2000 : customTimeout
  }
  return config
})

const nextApi = setupCache(
  nextApiInstance,
  {
    ttl: 0,
  }
)

serverApi.interceptors.request.use((config) => {
  if(config.method === 'get') {
    const customTimeout = Number(config.headers['custom-timeout'])
    config.timeout = Number.isNaN(customTimeout) ? 2000 : customTimeout
  }
  config.baseURL = ASP_APP_DIRECTORY_LOCAL_URL
  return config
})

clientApi.interceptors.request.use((config) => {
  if(typeof window !== 'undefined') {
    const token = sessionStorage.getItem('token')
    if(token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    const refreshToken = sessionStorage.getItem('refreshToken')
    if(token) {
      config.headers.refreshtoken = refreshToken
    }
    const subtenant = sessionStorage.getItem('subtenant') || ''
    if(subtenant) {
      config.headers['asp-subtenant'] = subtenant
    }
  }
  return config
})

serverApi.interceptors.response.use((response) => {
  return response.data
}, (error) => {
  return Promise.reject(error)
})

clientApi.interceptors.response.use((response) => {
  if(typeof window !== 'undefined') {
    if(response.headers['x-new-token']) {
      sessionStorage.setItem('token', response.headers['x-new-token'])
    }
  }
  return response.data
}, (error) => {
  return Promise.reject(error)
})

export {
  clientApi,
  nextApi,
  serverApi,
}
