import { useAppSelector } from '@common/hooks'


export interface IMySessionResponse {
  isAuthorizedUser: boolean,
}

export function useMyApp(): IMySessionResponse {
  const isAuthorizedUser = useAppSelector(store => {
    return Boolean(store.common.data.config?.token?.user_id)
  })
  return {
    isAuthorizedUser,
  }
}

export default useMyApp
