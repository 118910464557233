import type { IApiError } from '@apps/Application/widgets/AppSettingsForm'
import { useMemo } from 'react'

import useTranslate from './useTranslate'


interface IErrorResponce {
  serverErrorMessage: string
}

export default function useErrorMessages(apiError: IApiError | null): IErrorResponce {
  const { translate } = useTranslate()
  const serverErrorMessage = useMemo(() => {
    if(!apiError) {
      return ''
    }
    switch (apiError.status) {
      case 403:
        return translate('You must be logged in to install or update an app. Please close this window and log back in.')
      case 422:
        return (Array.isArray(apiError.detail) ? apiError.detail[0]?.msg : apiError.detail?.msg) || translate('Something went wrong. Please try again later.')
      default:
        return translate('Something went wrong. Please try again later.')
    }
  }, [apiError])

  return {
    serverErrorMessage,
  }
}
