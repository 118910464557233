import { emptyApplicationsArray } from '@common/constants'
import { useAppDispatch, useAppSelector } from '@common/hooks'
import { resetSlotId } from '@redux/slices/common/commonSlice'
import { useMemo } from 'react'


interface IErrorResponce {
  arrayOfSlot: string[],
  isLoading: boolean,
  resetSlot: () => void,
  slotId: number | undefined,
  slotForInstallation: number | undefined,
  userSlots: IApplication[]
}

export default function useSlots(): IErrorResponce {
  const dispatch = useAppDispatch()
  const userSlots = useAppSelector(store => store.userSlots.data?.items || emptyApplicationsArray)
  const isLoading = useAppSelector(store => store.userSlots.isLoading)
  const slotId = useAppSelector(store => store.common.data.slotId)
  const arrayOfSlot = useMemo<string[]>(() => {
    return [...Array(10).keys()]
      .map((ind) => {
        const app = userSlots.find(app => app.slot === ind + 1)
        return app?.id || ''
      })
  }, [userSlots])

  const slotForInstallation = (slotId && arrayOfSlot[slotId - 1] === '' ? slotId : undefined) || arrayOfSlot.findIndex(elem => elem === '') + 1

  const resetSlot = () => {
    void dispatch(resetSlotId())
  }
  return {
    arrayOfSlot,
    isLoading,
    resetSlot,
    slotId,
    slotForInstallation,
    userSlots,
  }
}
