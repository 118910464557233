import useTranslate from './useTranslate'


interface IPermissionResponce {
  getPermissionText: (permission: IPermissionKeyed) => string[] | undefined
}

export default function usePermissionText(): IPermissionResponce {
  const { translate, interpolate } = useTranslate()

  const getPermissionText = (permission: IPermissionKeyed): string[] | undefined => {
    const result = []
    if(permission.showWarningSingleApp && permission.showWarningAlreadyUsed) {
      result.push(interpolate(translate('Only one app can have this permission. Already used by %s.'), [permission.showWarningAlreadyUsed]))
    }
    if(permission.showWarningSingleApp && !permission.showWarningAlreadyUsed) {
      result.push(translate('Only one app can have this permission.'))
    }
    if(permission.showWarningActiveAppOverride) {
      result.push(translate('Apps running in "Active App" slot can override this permission.'))
    }
    return result.length ? result : undefined
  }

  return {
    getPermissionText,
  }
}
