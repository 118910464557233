import { clientApi as api } from '@common/api'
import { sortOrders } from '@common/constants'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'

import type { IData, IFilters } from './applicationList.types'


function createQueryString(obj: IFilters) {
  return Object.entries(obj)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')
}

const clientListRequest = async (params: IFilters) => {
  const requestId = createQueryString(params)
  if(requestStatusMap[requestId] === 'pending') {
    return null
  }
  requestStatusMap[requestId] = 'pending'
  try {
    const response = await api.get<IData>('/api/apps/list', { params, headers: { 'Custom-Timeout': 10000 } })
    requestStatusMap[requestId] = 'fulfilled'
    return response
  } catch (error) {
    requestStatusMap[requestId] = 'rejected'
    throw error
  }
}

export const fetchHackathonWinners = createAsyncThunk('hackathonWinners/clientFetchHackathonWinners', async () => {
  return await clientListRequest({ ...sortOrders.hackathonWinners, limit: 3, offset: 0 })
})

const requestStatusMap: { [key: string]: 'pending' | 'fulfilled' | 'rejected' } = {}

export const appListFetchThunks = {
  topEarning: createAsyncThunk(
    'topEarning/fetchMoreApplications',
    async (filters: IFilters, { rejectWithValue }) => {
      try {
        return await clientListRequest({ ...sortOrders.topEarning, ...filters })
      } catch (error) {
        const axiosError = error as AxiosError
        if(axiosError.response && axiosError.response.data) {
          return rejectWithValue(axiosError.response.data)
        }
      }
    }),
  trendingApps: createAsyncThunk(
    'trendingApps/fetchMoreApplications',
    async (filters: IFilters, { rejectWithValue }) => {
      try {
        return await clientListRequest({ ...sortOrders.trendingApps, ...filters })
      } catch (error) {
        const axiosError = error as AxiosError
        if(axiosError.response && axiosError.response.data) {
          return rejectWithValue(axiosError.response.data)
        }
      }
    }),
  newAndPopular: createAsyncThunk(
    'newAndPopular/fetchMoreApplications',
    async (filters: IFilters, { rejectWithValue }) => {
      try {
        return await clientListRequest({ ...sortOrders.newAndPopular, ...filters })
      } catch (error) {
        const axiosError = error as AxiosError
        if(axiosError.response && axiosError.response.data) {
          return rejectWithValue(axiosError.response.data)
        }
      }
    }),
  recentlyUpdated: createAsyncThunk(
    'recentlyUpdated/fetchMoreApplications',
    async (filters: IFilters, { rejectWithValue }) => {
      try {
        return await clientListRequest({ ...sortOrders.recentlyUpdated, ...filters })
      } catch (error) {
        const axiosError = error as AxiosError
        if(axiosError.response && axiosError.response.data) {
          return rejectWithValue(axiosError.response.data)
        }
      }
    }),
  recentlyUsed: createAsyncThunk(
    'recentlyUsed/fetchMoreApplications',
    async (filters: IFilters, { rejectWithValue }) => {
      try {
        return await clientListRequest({ ...sortOrders.recentlyUsed, ...filters })
      } catch (error) {
        const axiosError = error as AxiosError
        if(axiosError.response && axiosError.response.data) {
          return rejectWithValue(axiosError.response.data)
        }
      }
    }),
  searchApps: createAsyncThunk(
    'searchApps/fetchMoreApplications',
    async (filters: IFilters, { rejectWithValue }) => {
      try {
        return await clientListRequest(filters)
      } catch (error) {
        const axiosError = error as AxiosError
        if(axiosError.response && axiosError.response.data) {
          return rejectWithValue(axiosError.response.data)
        }
      }
    }
  ),
  hackathonWinners: createAsyncThunk(
    'hackathonWinners/fetchHackathonWinners',
    async (filters: IFilters, { rejectWithValue }) => {
      try {
        return await clientListRequest({ ...sortOrders.hackathonWinners, ...filters })
      } catch (error) {
        const axiosError = error as AxiosError
        if(axiosError.response && axiosError.response.data) {
          return rejectWithValue(axiosError.response.data)
        }
      }
    }
  ),
}

export const oneClickInstallAsync = createAsyncThunk(
  '/api/apps/install-one-click',
  async (req: IRequestAppInstallOneClick, { rejectWithValue }) => {
    try {
      return await api.post<unknown>('/api/apps/install-one-click', req)
    } catch (err) {
      const axiosError = err as AxiosError
      if(axiosError.response && axiosError.response.data) {
        return rejectWithValue(axiosError.response.data)
      }
      throw err
    }
  }
)


export const installAppFromListSync = createAction<{ id: string, slot: number}>('shared/installAppFromList')
