/* eslint-disable @typescript-eslint/naming-convention */
import { useTranslate } from '@common/hooks'
import type enTranslation from '@common/i18n/translations/en.json'
import ModalFooter from '@common/widgets/ModalFooter'
import { useRouter } from 'next/router'

import classNames from './styles.module.scss'


interface IProps {
  error: { message: string, detail?: string },
  onHide: () => void,
}

const descriptions: Record<string, keyof typeof enTranslation> = {
  common: 'Please check your internet connection and refresh the page or try again later.',
  'Item not found': 'The application you are looking for is not found. Please go to the home page and try again.',
}

const submitBtnText: Record<string, keyof typeof enTranslation> = {
  common: 'Refresh',
  'Item not found': 'Home page',
}

export default function ModalGlobalError({
  error,
  onHide,
}: IProps): JSX.Element {
  const { translate } = useTranslate()
  const router = useRouter()

  const handleClose = () => {
    const errorActions: Record<string, () => void> = {
      'Item not found': () => {
        void router.push('/apps')
        onHide()
      },
    }
    if(errorActions[error?.detail || '']) {
      errorActions[error.detail || '']()
      return
    }
    location.reload()
  }

  return (
    <>
      <div className={classNames.wrapper}>
        <h2 className={classNames.errorTitle}>{error?.message}</h2>
        <p className={classNames.description}>
          {translate(descriptions[error.detail || ''] || descriptions.common)}
        </p>
      </div>
      <ModalFooter
        onClose={handleClose}
        onHide={onHide}
        submitBtnText={submitBtnText[error.detail || ''] || submitBtnText.common}
        cancelBtnText="Ignore"
      />
    </>
  )
}
