import textTranslations from '@common/i18n/translations'
import enTranslation from '@common/i18n/translations/en.json'
import { useRouter } from 'next/router'

interface IResponse {
  translate: (key: keyof typeof enTranslation) => string,
  lang: string,
  interpolate: (format: string, values: (string|number)[]) => string,
}

export default function useTranslate(): IResponse {
  const { locale } = useRouter()

  const translate = (text: keyof typeof enTranslation): string => {
    if(locale === undefined || textTranslations[locale] === undefined) {
      return enTranslation[text]
    }
    return textTranslations[locale][text] || textTranslations.en[text]
  }

  const interpolate = (format: string, values: (string|number)[]): string => {
    return format.replace(/%s/g, function() { return String(values.shift()) })
  }

  return {
    lang: locale || 'en',
    translate,
    interpolate,
  }
}
