import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'

// slices
import application from './slices/application/application'
import applicationListSlices from './slices/applicationList'
import applicationRating from './slices/applicationRating'
import appVersions from './slices/appVersions'
import common from './slices/common'
import featureFlags from './slices/featureFlags'
import userSlots from './slices/userSlots'


const store = configureStore({
  reducer: {
    application,
    applicationRating,
    appVersions,
    topEarning: applicationListSlices.topEarning,
    trendingApps: applicationListSlices.trendingApps,
    newAndPopular: applicationListSlices.newAndPopular,
    searchApps: applicationListSlices.searchApps,
    recentlyUpdated: applicationListSlices.recentlyUpdated,
    recentlyUsed: applicationListSlices.recentlyUsed,
    hackathonWinners: applicationListSlices.hackathonWinners,
    common,
    userSlots,
    featureFlags,
  },
})


const makeStore = () => (
  configureStore({
    reducer: {
      application,
      applicationRating,
      appVersions,
      topEarning: applicationListSlices.topEarning,
      trendingApps: applicationListSlices.trendingApps,
      newAndPopular: applicationListSlices.newAndPopular,
      searchApps: applicationListSlices.searchApps,
      recentlyUpdated: applicationListSlices.recentlyUpdated,
      recentlyUsed: applicationListSlices.recentlyUsed,
      hackathonWinners: applicationListSlices.hackathonWinners,
      common,
      userSlots,
      featureFlags,
    },
    devTools: true,
  })
)

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


export const wrapper = createWrapper(makeStore, {
  serializeState: (state) => JSON.stringify(state),
  deserializeState: (state: string) => JSON.parse(state),
})
